import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SeriesItemObj } from "../../pages/Series/Series.types";
import { RootState } from "../store";

export interface SeriesState {
  seriesList: SeriesItemObj[];
  isLoading: boolean;
}

const initialState: SeriesState = {
  seriesList: [],
  isLoading: true,
};

export const seriesSlice = createSlice({
  name: "series",
  initialState,
  reducers: {
    setSeriesList: (state, action: PayloadAction<SeriesItemObj[]>) => {
      state.seriesList = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setSeriesList } = seriesSlice.actions;
export default seriesSlice.reducer;

const path = (state: RootState) => state.series;
export const getSeriesList = (state: RootState) => path(state).seriesList;
export const isSeriesListLoading = (state: RootState) => path(state).isLoading;
