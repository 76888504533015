import { configureStore } from "@reduxjs/toolkit";
import { genesisSlice } from "./slices/GenesisSlice";
import { seriesSlice } from "./slices/SeriesSlice";
import { errorSlice } from "./slices/ErrorSlice";
import { gallerySlice } from "./slices/GallerySlice";
import { bookSlice } from "./slices/BooksSlice";

export const store = configureStore({
  reducer: {
    genesis: genesisSlice.reducer,
    series: seriesSlice.reducer,
    error: errorSlice.reducer,
    gallery: gallerySlice.reducer,
    books: bookSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
