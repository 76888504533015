import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CenteredContent } from "../../common/styles";
import { ImageDisplay } from "../../components/ImageDisplay";
import {
  getGenesisLandingImage,
  isGenesisLoading,
  isGenesisPreloading,
  setIsPreloadingLandingImage,
} from "../../redux/slices/GenesisSlice";
import {
  GENESIS_IMAGE_MAX_HEIGHT,
  GENESIS_IMAGE_MAX_WIDTH,
} from "./Genesis.constants";
import { useFetchLandingImage } from "./hooks/useFetchLandingImage";
import { LoadingState } from "../../components/LoadingState";
import { preloadImage } from "../../common/utils";

export const Genesis: React.FC = () => {
  const fetchLandingImage = useFetchLandingImage();

  const isLoading = useSelector(isGenesisLoading);
  const isPreloading = useSelector(isGenesisPreloading);
  const landingImage = useSelector(getGenesisLandingImage);

  const isInitialFetch = useRef(!landingImage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInitialFetch.current) {
      fetchLandingImage();
    }

    isInitialFetch.current = false;
  }, [fetchLandingImage]);

  useEffect(() => {
    if (landingImage) {
      const preloadCurrentImage = async () => {
        dispatch(setIsPreloadingLandingImage(true));
        await preloadImage(landingImage.url);
        dispatch(setIsPreloadingLandingImage(false));
      };

      preloadCurrentImage();
    }
  }, [landingImage]);

  if (!isLoading && !landingImage) {
    return <LoadingState />;
  }

  return (
    <CenteredContent>
      <CenteredContent
        width={GENESIS_IMAGE_MAX_WIDTH}
        height={GENESIS_IMAGE_MAX_HEIGHT}
      >
        {isLoading || isPreloading ? (
          <LoadingState />
        ) : (
          <ImageDisplay
            src={landingImage?.url ?? ""}
            maxHeight={GENESIS_IMAGE_MAX_HEIGHT}
            maxWidth={GENESIS_IMAGE_MAX_WIDTH}
            alt={"genesis"}
          />
        )}
      </CenteredContent>
    </CenteredContent>
  );
};
