export interface RouteInfo {
  name: string; // navbar text
  title: string; // tab title
  path: string; // url
}

export const homeRoute: RouteInfo = {
  name: "genesis",
  title: "Genesis",
  path: "/",
};

export const genesisRoute: RouteInfo = {
  name: "genesis",
  title: "Genesis",
  path: "/genesis",
};

export const seriesRoute: RouteInfo = {
  name: "series",
  title: "Series",
  path: "/series",
};

export const seriesGalleryRoute: RouteInfo = {
  name: "series",
  title: "Series",
  path: "/series/:name",
};

export const booksRoute: RouteInfo = {
  name: "books",
  title: "Books",
  path: "/books",
};

export const booksGalleryRoute: RouteInfo = {
  name: "books",
  title: "Books",
  path: "/books/:name",
};

export const infoRoute: RouteInfo = {
  name: "info",
  title: "Info",
  path: "/info",
};

export const errorRoute: RouteInfo = {
  name: "error",
  title: "something went wrong...",
  path: "/error",
};

export const notFoundRoute: RouteInfo = {
  name: "Not found",
  title: "Not found",
  path: "*",
};

export const allRoutes: RouteInfo[] = [
  homeRoute,
  genesisRoute,
  seriesRoute,
  seriesGalleryRoute,
  booksRoute,
  infoRoute,
  notFoundRoute,
];

export const navbarRoutes = {
  leftLinks: [genesisRoute],
  rightLinks: [seriesRoute, booksRoute, infoRoute],
};
