import { LinearProgress, styled } from "@mui/material";
import { CenteredContent } from "../../common/styles";

export const LoadingStateWrapper = styled(CenteredContent)(({ theme }) => ({
  height: "100%",
}));

export const LoadingBar = styled(LinearProgress)(({ theme }) => ({
  width: theme.spacing(10),
  height: "0.15vh",
  flexGrow: 1,
}));
