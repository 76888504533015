import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ErrorState {
  isError: boolean;
}

const initialState: ErrorState = {
  isError: false,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
  },
});

export const { setIsError } = errorSlice.actions;
export default errorSlice.reducer;

const path = (state: RootState) => state.error;
export const getIsError = (state: RootState) => path(state).isError;
