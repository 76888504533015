import { Box, styled } from "@mui/material";

export const NavbarContainer = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(8), // 64px
  padding: `0 ${theme.spacing(6)}`,

  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const LeftLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
}));

export const RightLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
}));
