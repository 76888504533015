import { Box, styled } from "@mui/material";

export const StyledLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const MainPanel = styled(Box)(({ theme }) => ({
  flexGrow: 1,
}));
