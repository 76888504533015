import { Box, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const CenteredContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

export const CenteredGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,

  "&:hover": {
    borderBottom: `solid 1px ${theme.palette.text.primary}`,
  },
}));
