import { list, ref } from "firebase/storage";
import { useMemo } from "react";
import { storage } from "./firebase";

export const useStorage = () => {
  return useMemo(
    () => (path: string) => {
      const storageRef = ref(storage, path);

      return list(storageRef);
    },
    []
  );
};
