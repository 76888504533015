import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FirebaseImageInfo } from "../../config/firebase/firebase.types";
import { RootState } from "../store";

export interface GenesisState {
  isLoading: boolean;
  isPreloading: boolean;
  landingImage?: FirebaseImageInfo;
}

const initialState: GenesisState = {
  isLoading: true,
  isPreloading: true,
};

export const genesisSlice = createSlice({
  name: "genesis",
  initialState,
  reducers: {
    setLandingImage: (state, action: PayloadAction<FirebaseImageInfo>) => {
      state.landingImage = action.payload;
      state.isLoading = false;
    },
    setIsLoadingLandingImage: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsPreloadingLandingImage: (state, action: PayloadAction<boolean>) => {
      state.isPreloading = action.payload;
    },
  },
});

export const {
  setLandingImage,
  setIsLoadingLandingImage,
  setIsPreloadingLandingImage,
} = genesisSlice.actions;
export default genesisSlice.reducer;

const path = (state: RootState) => state.genesis;
export const getGenesisLandingImage = (state: RootState) =>
  path(state).landingImage;
export const isGenesisLoading = (state: RootState) => path(state).isLoading;
export const isGenesisPreloading = (state: RootState) =>
  path(state).isPreloading;
