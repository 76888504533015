import { Box, Grid, styled } from "@mui/material";
import { CenteredGrid } from "../../common/styles";

export const BookListContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "center",
  gap: theme.spacing(3),
}));

export const BookCoverContainer = styled(CenteredGrid)(({ theme }) => ({
  position: "relative",
}));
