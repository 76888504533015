import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CenteredContent, StyledLink } from "../../common/styles";
import { ImageDisplay } from "../../components/ImageDisplay";
import { LoadingState } from "../../components/LoadingState";
import { getBookList, isBookListLoading } from "../../redux/slices/BooksSlice";
import { BookCoverContainer, BookListContainer } from "./Books.styled";
import { useFetchBookCovers } from "./hooks/useFetchBookCovers";
import {
  BOOK_COVER_IMAGE_MAX_HEIGHT,
  BOOK_COVER_IMAGE_MAX_WIDTH,
} from "./Books.constants";

export const Books = () => {
  const bookList = useSelector(getBookList);
  const isLoading = useSelector(isBookListLoading);

  const fetchBookList = useFetchBookCovers();
  const [hoveredBook, setHoveredBook] = useState<string | null>(null);

  const isInitialFetch = useRef(true);

  useEffect(() => {
    if (isInitialFetch) {
      fetchBookList();
    }
  }, []);

  const renderBookTitles = () => {
    return bookList.map((book) => (
      <Typography
        variant="h6"
        onMouseEnter={() => setHoveredBook(book.name)}
        onMouseLeave={() => setHoveredBook(null)}
      >
        <StyledLink to={book.name}>{book.name}</StyledLink>
      </Typography>
    ));
  };

  const renderBookCovers = () => {
    return bookList.map((book) => (
      <ImageDisplay
        maxWidth={BOOK_COVER_IMAGE_MAX_WIDTH}
        maxHeight={BOOK_COVER_IMAGE_MAX_HEIGHT}
        src={book.coverUrl}
        alt={book.name}
        sx={{
          position: "absolute",
          opacity: hoveredBook === book.name ? 1 : 0,
          transition: "0.1s ease-in-out",
        }}
      />
    ));
  };

  return (
    <>
      {isLoading ? (
        <CenteredContent>
          <LoadingState />
        </CenteredContent>
      ) : (
        <Grid container height={"100%"}>
          <>
            <BookListContainer item sm={3}>
              {renderBookTitles()}
            </BookListContainer>
            <BookCoverContainer item sm={9}>
              {renderBookCovers()}
            </BookCoverContainer>
          </>
        </Grid>
      )}
    </>
  );
};
