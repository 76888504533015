import { SeriesItemPosition } from "./SeriesItem.types";

export const SERIES_ITEM_IMAGE_MAX_HEIGHT = "35vh";
export const SERIES_ITEM_IMAGE_MAX_WIDTH = "40vw";

export const POSITIONS: SeriesItemPosition[] = [
  {
    link: {
      marginLeft: "15vw",
      marginTop: "20vh",
    },
    cover: {
      marginLeft: "26vw",
      marginTop: "20vh",
    },
  },
  {
    link: {
      marginLeft: "70vw",
      marginTop: "60vh",
    },
    cover: {
      marginLeft: "48vw",
      marginTop: "20vh",
    },
  },
  {
    link: {
      marginLeft: "50vw",
      marginTop: "10vh",
    },
    cover: {
      marginLeft: "25vw",
      marginTop: "29vh",
    },
  },
  {
    link: {
      marginLeft: "10vw",
      marginTop: "70vh",
    },
    cover: {
      marginLeft: "21vw",
      marginTop: "38vh",
    },
  },
  {
    link: {
      marginLeft: "40vw",
      marginTop: "45vh",
    },
    cover: {
      marginLeft: "50vw",
      marginTop: "25vh",
    },
  },
  {
    link: {
      marginLeft: "80vw",
      marginTop: "15vh",
    },
    cover: {
      marginLeft: "65vw",
      marginTop: "25vh",
    },
  },
];
