import { Box, Grid, Typography } from "@mui/material";
import { ImageDisplay } from "../../components/ImageDisplay";
import { StyledLink } from "../../common/styles";

export const Info = () => {
  return (
    <Grid container paddingTop={8}>
      <Grid
        item
        sm={6}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={12}
      >
        <Box>
          <ImageDisplay
            src={"./info-image-1.jpg"}
            alt={"gallery"}
            maxHeight={"auto"}
            maxWidth={"20vw"}
          />
        </Box>
        <Box>
          <ImageDisplay
            src={"./info-image-2.jpg"}
            alt={"gallery"}
            maxHeight={"auto"}
            maxWidth={"20vw"}
          />
        </Box>
      </Grid>
      <Grid item sm={6} display={"flex"} flexDirection={"row"}>
        <Grid item sm={3} />
        <Grid item sm={6}>
          <Box marginBottom={6} width={"fit-content"}>
            <Typography variant="body1">
              <StyledLink
                target="_blank"
                to={"https://www.instagram.com/bernardocarvalho25"}
              >
                Instagram
              </StyledLink>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              Bernardo Carvalho n.1999, Lisboa.
            </Typography>
            <Typography variant="body1">
              2021-2023 Higher Nationals Diploma Photography at ETIC
            </Typography>
            <Typography variant="body1">Worked at Gamut LDA</Typography>
            <Typography variant="body1">Exhibitions:</Typography>
            <Typography variant="body1">
              - “Mãe”, Garagem Imago, Lisboa 2022 ;
            </Typography>
            <Typography variant="body1">
              - “Coletiva alunos ETIC”, Casa da Imprensa, Chiado 2023/2024
            </Typography>
          </Box>
          <Box marginTop={6} width={"fit-content"}>
            <Typography variant="body1">
              Website developed by&nbsp;
              <StyledLink
                target="_blank"
                to={"https://www.linkedin.com/in/alexmsa22/"}
              >
                Alexandre Sá
              </StyledLink>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
