import { CenteredContent } from "../../common/styles";
import { LoadingBar, LoadingStateWrapper } from "./LoadingState.styled";

export const LoadingState = () => {
  // Needed double CenteredContent, investigate further how to simplify this
  return (
    <CenteredContent height={"100%"}>
      <LoadingStateWrapper>
        <LoadingBar />
      </LoadingStateWrapper>
    </CenteredContent>
  );
};
