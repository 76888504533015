import { Box } from "@mui/material";
import { StyledLink } from "../../../common/styles";
import { ImageDisplay } from "../../../components/ImageDisplay";
import { SeriesItemObj } from "../Series.types";
import {
  SERIES_ITEM_IMAGE_MAX_HEIGHT,
  SERIES_ITEM_IMAGE_MAX_WIDTH,
} from "./SeriesItem.constants";
import {
  SeriesItemImageWrapper,
  SeriesItemLinkWrapper,
} from "./SeriesItem.styled";
import { SeriesItemPosition } from "./SeriesItem.types";

interface SeriesItemProps {
  seriesGridItem: SeriesItemObj;
  position: SeriesItemPosition;
}

export const SeriesItem = ({ seriesGridItem, position }: SeriesItemProps) => {
  return (
    <Box className="series-item-wrapper">
      <SeriesItemLinkWrapper {...position.link}>
        <StyledLink to={seriesGridItem.name}>{seriesGridItem.name}</StyledLink>
      </SeriesItemLinkWrapper>
      <SeriesItemImageWrapper {...position.cover}>
        <ImageDisplay
          src={seriesGridItem.coverUrl}
          maxHeight={SERIES_ITEM_IMAGE_MAX_HEIGHT}
          maxWidth={SERIES_ITEM_IMAGE_MAX_WIDTH}
          alt={seriesGridItem.name}
        />
      </SeriesItemImageWrapper>
    </Box>
  );
};
