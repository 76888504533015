import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BooksItemObj } from "../../pages/Books/Books.types";
import { RootState } from "../store";

export interface BooksState {
  bookList: BooksItemObj[];
  isLoading: boolean;
}

const initialState: BooksState = {
  bookList: [],
  isLoading: true,
};

export const bookSlice = createSlice({
  name: "series",
  initialState,
  reducers: {
    setBookList: (state, action: PayloadAction<BooksItemObj[]>) => {
      state.bookList = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setBookList } = bookSlice.actions;
export default bookSlice.reducer;

const path = (state: RootState) => state.books;
export const getBookList = (state: RootState) => path(state).bookList;
export const isBookListLoading = (state: RootState) => path(state).isLoading;
