import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CenteredContent } from "../../common/styles";
import { ImageDisplay } from "../../components/ImageDisplay";
import { LoadingState } from "../../components/LoadingState";
import {
  getCurrentGalleryImage,
  isGalleryLoading,
  isGalleryPreloading,
  setIsGalleryLoading,
  setIsGalleryPreloading,
  setNextGalleryImage,
  setPreviousGalleryImage,
} from "../../redux/slices/GallerySlice";
import { GalleryWrapper } from "./Gallery.styled";
import { isMouseLeftSide } from "./Gallery.utils";
import { useFetchGalleryImages } from "./hooks/useFetchGalleryImages";
import { preloadImage } from "../../common/utils";
import {
  GALLERY_IMAGE_MAX_HEIGHT,
  GALLERY_IMAGE_MAX_WIDTH,
} from "./Gallery.constants";
import { SERIES_CONTENT_BUCKET } from "../Series/Series.constants";
import { BOOK_CONTENT_BUCKET } from "../Books/Books.constants";

const RIGHT_ARROW_CURSOR_CLASS = "right-cursor";
const LEFT_ARROW_CURSOR_CLASS = "left-cursor";

interface GalleryProps {
  bucket: typeof SERIES_CONTENT_BUCKET | typeof BOOK_CONTENT_BUCKET;
}

export const Gallery = ({ bucket }: GalleryProps) => {
  const { name } = useParams();

  const dispatch = useDispatch();

  const fetchGalleryImages = useFetchGalleryImages();

  const { currentImage, nextImage, previousImage } = useSelector(
    getCurrentGalleryImage
  );
  const isLoading = useSelector(isGalleryLoading);
  const isPreloadingImage = useSelector(isGalleryPreloading);

  const mouseContainerRef = useRef<HTMLDivElement | null>(null);

  const isInitialFetch = useRef(true);

  useEffect(() => {
    if (name && isInitialFetch.current) {
      fetchGalleryImages(`${bucket}/${name}`);
      isInitialFetch.current = false;
    }
  }, [name, isInitialFetch]);

  useEffect(() => {
    if (currentImage) {
      const preloadCurrentImage = async () => {
        dispatch(setIsGalleryPreloading(true));
        await preloadImage(currentImage);
        dispatch(setIsGalleryPreloading(false));
      };

      preloadCurrentImage();

      if (nextImage) preloadImage(nextImage);
      if (previousImage) preloadImage(previousImage);
    } else {
      dispatch(setIsGalleryLoading(false));
      dispatch(setIsGalleryPreloading(false));
    }
  }, [currentImage]);

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!mouseContainerRef.current) return;

    if (isMouseLeftSide(e)) {
      // Left click
      mouseContainerRef.current.classList.remove(RIGHT_ARROW_CURSOR_CLASS);
      mouseContainerRef.current.classList.add(LEFT_ARROW_CURSOR_CLASS);
    } else {
      // Right click
      mouseContainerRef.current.classList.remove(LEFT_ARROW_CURSOR_CLASS);
      mouseContainerRef.current.classList.add(RIGHT_ARROW_CURSOR_CLASS);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!mouseContainerRef.current) return;

    if (isMouseLeftSide(e)) {
      // Left click
      dispatch(setPreviousGalleryImage());
    } else {
      // Right click
      dispatch(setNextGalleryImage());
    }
  };

  return (
    <CenteredContent width={"100%"}>
      <GalleryWrapper
        ref={mouseContainerRef}
        onMouseMove={onMouseMove}
        onClick={onClick}
      >
        {isLoading || isPreloadingImage ? (
          <LoadingState />
        ) : (
          <>
            {currentImage && (
              <ImageDisplay
                src={currentImage}
                alt={"gallery"}
                maxHeight={GALLERY_IMAGE_MAX_HEIGHT}
                maxWidth={GALLERY_IMAGE_MAX_WIDTH}
              />
            )}
          </>
        )}
      </GalleryWrapper>
    </CenteredContent>
  );
};
