import { Typography } from "@mui/material";
import { CenteredContent } from "../../common/styles";

export const Error = () => {
  return (
    <CenteredContent height={"100%"} flexDirection={"column"} gap={1}>
      <Typography variant="h6">Something went wrong...</Typography>
      <Typography variant="body1">Please try again later</Typography>
    </CenteredContent>
  );
};
