import { Box, styled } from "@mui/material";

export const GalleryWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "default",

  "&.left-cursor": {
    cursor: "url('/left-arrow.svg'), auto",
  },

  "&.right-cursor": {
    cursor: "url('/right-arrow.svg'), auto",
  },
}));
