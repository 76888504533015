import { Typography } from "@mui/material";
import { StyledLink } from "../../common/styles";
import { RouteInfo, navbarRoutes } from "../../config/routes/routes";
import {
  LeftLinksContainer,
  NavbarContainer,
  RightLinksContainer,
} from "./Navbar.styled";

export const Navbar = () => {
  const leftLinks = navbarRoutes.leftLinks;
  const rightLinks = navbarRoutes.rightLinks;

  const renderRoutes = (routes: RouteInfo[]) => {
    return routes.map((route) => (
      <Typography variant="h5">
        <StyledLink key={route.name} to={route.path}>
          {route.name}
        </StyledLink>
      </Typography>
    ));
  };

  return (
    <NavbarContainer>
      <LeftLinksContainer>{renderRoutes(leftLinks)}</LeftLinksContainer>
      <RightLinksContainer>{renderRoutes(rightLinks)}</RightLinksContainer>
    </NavbarContainer>
  );
};
