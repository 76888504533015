import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../components/LoadingState";
import { getSeriesList } from "../../redux/slices/SeriesSlice";
import { SeriesItemObj } from "./Series.types";
import { getSeriesItemPosition } from "./SeriesItem/SeriesItem.utils";
import { useFetchSeriesCovers } from "./hooks/useFetchSeriesCovers";
import { SeriesItem } from "./SeriesItem";
import { SeriesWrapper } from "./Series.styled";

export const Series = () => {
  const fetchSeries = useFetchSeriesCovers();

  const seriesList = useSelector(getSeriesList);

  const isInitialFetch = useRef(true);

  useEffect(() => {
    if (isInitialFetch.current && !seriesList.length) {
      fetchSeries();
    }

    isInitialFetch.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSeries]);

  if (!seriesList.length) {
    return <LoadingState />;
  }

  const renderSeriesItem = (item: SeriesItemObj, idx: number) => {
    return (
      <SeriesItem seriesGridItem={item} position={getSeriesItemPosition(idx)} />
    );
  };

  return <SeriesWrapper>{seriesList.map(renderSeriesItem)}</SeriesWrapper>;
};
