import { getDownloadURL } from "firebase/storage";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useStorage } from "../../../config/firebase/useStorage";
import { setIsError } from "../../../redux/slices/ErrorSlice";
import {
  setGalleryImages,
  setIsGalleryLoading,
  setIsGalleryPreloading,
} from "../../../redux/slices/GallerySlice";

export const useFetchGalleryImages = () => {
  const storage = useStorage();
  const dispatch = useDispatch();

  return useMemo(
    () => (bucket: string) => {
      dispatch(setIsGalleryLoading(true));
      dispatch(setIsGalleryPreloading(true));

      storage(bucket)
        .then((res) => {
          const urlPromises = res.items.map(getDownloadURL);

          console.log(urlPromises);

          if (urlPromises.length === 0) {
            dispatch(setIsError(true));
            dispatch(setIsGalleryLoading(false));
            dispatch(setIsGalleryPreloading(false));
            return;
          }

          Promise.all(urlPromises).then((urls) => {
            dispatch(setGalleryImages(urls));
            dispatch(setIsGalleryLoading(false));
          });
        })
        .catch(() => {
          dispatch(setIsError(true));
          dispatch(setIsGalleryLoading(false));
          dispatch(setIsGalleryPreloading(false));
        });
    },
    [dispatch, storage]
  );
};
