import { Box, styled } from "@mui/material";

export const SeriesWrapper = styled(Box)(({ theme }) => ({
  div: {
    transition: "opacity 0.2s ease-in-out",
  },

  "&:hover div": {
    transition: "opacity 0.2s ease-in-out",
    opacity: 0,
    visibility: "hidden",

    div: {
      transition: "opacity 0.2s ease-in-out",
      opacity: 0,
      visibility: "hidden",
    },
  },

  "&:hover div:hover": {
    transition: "opacity 0.2s ease-in-out",
    opacity: 1,
    visibility: "visible",

    div: {
      // Cover
      transition: "opacity 0.2s ease-in-out",
      opacity: 1,
      visibility: "visible",
    },
  },
}));
