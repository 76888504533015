import { Box, SxProps } from "@mui/material";
import { StyledImg } from "./ImageDisplay.styled";

interface ImageDisplayProps {
  src: string;
  alt: string;
  maxHeight?: React.CSSProperties["maxHeight"];
  maxWidth?: React.CSSProperties["maxWidth"];
  sx?: SxProps;
}

export const ImageDisplay = (props: ImageDisplayProps) => {
  const { src, alt, maxHeight, maxWidth, sx } = props;

  return (
    <Box
      sx={{
        img: {
          height: "auto",
          width: "auto",
          maxHeight: maxHeight,
          maxWidth: maxWidth,
        },
        ...sx,
      }}
    >
      <StyledImg src={src} alt={alt} />
    </Box>
  );
};
