import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getIsError, setIsError } from "../redux/slices/ErrorSlice";
import { errorRoute } from "../config/routes/routes";

interface PageProps {
  children: React.ReactElement;
}

export const Page = ({ children }: PageProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isError = useSelector(getIsError);

  const getPageTitleFromPath = () => {
    const path = location.pathname?.slice(1);

    if (path === "" || path === "/") {
      return "genesis";
    }

    return path || "Bernardo Carvalho";
  };

  useEffect(() => {
    document.title = getPageTitleFromPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isError) {
      dispatch(setIsError(false));
      navigate(errorRoute.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return children;
};
