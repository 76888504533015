import { Link, Typography } from "@mui/material";
import { CenteredContent } from "../../common/styles";
import { genesisRoute } from "../../config/routes/routes";

export const NotFound = () => {
  return (
    <CenteredContent height={"100%"} flexDirection={"column"} gap={1}>
      <Typography variant="h6">404 Not Found</Typography>
      <Link href={genesisRoute.path}>
        <Typography variant="body1">Back to genesis</Typography>
      </Link>
    </CenteredContent>
  );
};
