import { getDownloadURL } from "firebase/storage";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useStorage } from "../../../config/firebase/useStorage";
import { setLandingImage } from "../../../redux/slices/GenesisSlice";
import { GENESIS_PAGE_BUCKET } from "../Genesis.constants";
import { setIsError } from "../../../redux/slices/ErrorSlice";

export const useFetchLandingImage = () => {
  const storage = useStorage();
  const dispatch = useDispatch();

  return useMemo(
    () => () => {
      console.log("requezt");
      storage(GENESIS_PAGE_BUCKET)
        .then((res) => {
          console.log("requezt");
          const randomIndex = Math.floor(Math.random() * res.items.length);
          const randomItemRef = res.items[randomIndex];

          return getDownloadURL(randomItemRef).then((url) => {
            const image = { url, item: randomItemRef };
            dispatch(setLandingImage(image));
          });
        })
        .catch((error: any) => {
          dispatch(setIsError(true));
          console.log(error);
        });
    },
    [dispatch, storage]
  );
};
