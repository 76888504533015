import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import {
  booksGalleryRoute,
  booksRoute,
  errorRoute,
  genesisRoute,
  homeRoute,
  infoRoute,
  notFoundRoute,
  seriesGalleryRoute,
  seriesRoute,
} from "./config/routes/routes";
import Books from "./pages/Books";
import Genesis from "./pages/Genesis";
import Info from "./pages/Info";
import NotFound from "./pages/NotFound";
import { Page } from "./pages/Page";
import Series from "./pages/Series";
import { store } from "./redux/store";
import { bcTheme } from "./system/theme/Theme";
import { Error } from "./pages/Error";
import { Gallery } from "./pages/Gallery";
import { SERIES_CONTENT_BUCKET } from "./pages/Series/Series.constants";
import { BOOK_CONTENT_BUCKET } from "./pages/Books/Books.constants";

export const App = () => {
  return (
    <ThemeProvider theme={bcTheme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <AppLayout>
            <Page>
              <Routes>
                <Route path={homeRoute.path} element={<Genesis />} />
                <Route path={genesisRoute.path} element={<Genesis />} />
                <Route path={seriesRoute.path} element={<Series />} />
                <Route
                  path={seriesGalleryRoute.path}
                  element={<Gallery bucket={SERIES_CONTENT_BUCKET} />}
                />
                <Route path={booksRoute.path} element={<Books />} />
                <Route
                  path={booksGalleryRoute.path}
                  element={<Gallery bucket={BOOK_CONTENT_BUCKET} />}
                />
                <Route path={infoRoute.path} element={<Info />} />
                <Route path={notFoundRoute.path} element={<NotFound />} />
                <Route path={errorRoute.path} element={<Error />} />
              </Routes>
            </Page>
          </AppLayout>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};
