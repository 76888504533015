import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyATb2COVt2EFGtt_zKdY_qklIM0S_e_Tks",
  authDomain: "website-2b3b6.firebaseapp.com",
  projectId: "website-2b3b6",
  storageBucket: "website-2b3b6.appspot.com",
  messagingSenderId: "255899079717",
  appId: "1:255899079717:web:19640670c16ba885779bcc",
  measurementId: "G-E75E4V8CVE",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
