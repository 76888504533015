import { Typography, useMediaQuery } from "@mui/material";
import Navbar from "../Navbar";
import { StyledLayout, MainPanel } from "./AppLayout.styled";
import { CenteredContent } from "../../common/styles";

interface AppLayoutProps {
  children: React.ReactElement;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <StyledLayout>
      {isMobile ? (
        <CenteredContent flexDirection={"column"}>
          <Typography variant="h6">
            This site is best viewed on a desktop.
          </Typography>
          <Typography variant="h6">Mobile support is coming soon.</Typography>
        </CenteredContent>
      ) : (
        <>
          <Navbar />
          <MainPanel>{children}</MainPanel>
        </>
      )}
    </StyledLayout>
  );
};
