import { createTheme } from "@mui/material";

export const bcTheme = createTheme({
  palette: {
    primary: {
      light: "#afb1b3", // TBD
      main: "#262526", // TBD
      dark: "#181718", // TBD
      contrastText: "#000000", // Black
    },
    secondary: {
      light: "#f50a51", // TBD
      main: "#cc0a45", // TBD
      dark: "#b0093c", // TBD
      contrastText: "#FFFFFF", // TBD
    },
    error: {
      main: "#cc0a45", // TBD
    },
    warning: {
      light: "#f50a51", // TBD
      main: "#cc0a45", // TBD
      dark: "#CD7F32", // TBD
    },
    info: {
      main: "#e6e6e6", // TBD
      dark: "#90def0", // TBD
    },
    success: {
      main: "#3bd100", // TBD
      light: "#FFDA00", // TBD
    },
    background: {
      default: "#ffffff", // White background
      // paper: "#F5F5F5",
    },
    text: {
      primary: "#000000", // Black
      secondary: "#e1e2e3", // TBD
    },
  },
  typography: {
    fontFamily: "'Cormorant', sans-serif", // TBD

    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: "3.5rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.8rem",
    },
    h5: {
      fontSize: "1.6rem",
    },
    h6: {
      fontSize: "1.4rem",
    },
    body1: {
      fontSize: "1.275rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
});
