import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const SeriesItemLink = styled(Link)(({ theme }) => ({
  textDecoration: "inherit",
  color: "inherit",
  opacity: 1,
  visibility: "visible",
}));

export const SeriesItemWrapper = styled(Box)(({ theme }) => ({
  opacity: 1,
  visibility: "visible",
}));

export const SeriesItemImageWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",

  opacity: 0,
  visibility: "hidden",
}));

export const SeriesItemLinkWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
}));
