import { getDownloadURL, list, ref } from "firebase/storage";
import { useMemo } from "react";
import { useStorage } from "../../../config/firebase/useStorage";
import { SERIES_COVERS_BUCKET } from "../Series.constants";
import { SeriesItemObj } from "../Series.types";
import { extractFileName } from "../../../common/utils";
import { useDispatch } from "react-redux";
import { setSeriesList } from "../../../redux/slices/SeriesSlice";
import { setIsError } from "../../../redux/slices/ErrorSlice";

export const useFetchSeriesCovers = () => {
  const storage = useStorage();
  const dispatch = useDispatch();

  return useMemo(
    () => () => {
      storage(SERIES_COVERS_BUCKET)
        .then((res) => {
          const urlPromises = res.items.map(getDownloadURL);

          if (!urlPromises.length) {
            dispatch(setIsError(true));
            return;
          }

          Promise.all(urlPromises).then((urls) => {
            const seriesList: SeriesItemObj[] = [];

            urls.forEach((url, idx) => {
              seriesList.push({
                name: extractFileName(res.items[idx].name),
                coverUrl: url,
              });
            });

            dispatch(setSeriesList(seriesList));
          });
        })
        .catch(() => dispatch(setIsError(true)));
    },
    [dispatch, storage]
  );
};
