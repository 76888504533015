export const extractFileName = (fileName: string) => {
  const slashSplit = fileName.split("/");

  return slashSplit[slashSplit.length - 1].split(".jpg")[0];
};

export const preloadImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};
