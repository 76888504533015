import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface GalleryState {
  images: string[];
  isLoading: boolean;
  isPreloading: boolean;
  currentImageIdx: number;
}

const initialState: GalleryState = {
  images: [],
  isLoading: true,
  isPreloading: true,
  currentImageIdx: 0,
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setGalleryImages: (state, action: PayloadAction<string[]>) => {
      state.images = action.payload;
      state.isLoading = false;
      state.currentImageIdx = 0;
    },
    setGalleryCurrentImageIdx: (state, action: PayloadAction<number>) => {
      state.currentImageIdx = action.payload;
    },
    setPreviousGalleryImage: (state) => {
      state.currentImageIdx =
        state.currentImageIdx === 0
          ? state.images.length - 1
          : state.currentImageIdx - 1;
    },
    setNextGalleryImage: (state) => {
      state.currentImageIdx =
        state.currentImageIdx === state.images.length - 1
          ? 0
          : state.currentImageIdx + 1;
    },
    setIsGalleryLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.currentImageIdx = 0;
    },
    setIsGalleryPreloading: (state, action: PayloadAction<boolean>) => {
      state.isPreloading = action.payload;
    },
  },
});

export const {
  setGalleryImages,
  setNextGalleryImage,
  setPreviousGalleryImage,
  setGalleryCurrentImageIdx,
  setIsGalleryLoading,
  setIsGalleryPreloading,
} = gallerySlice.actions;
export default gallerySlice.reducer;

const path = (state: RootState) => state.gallery;
export const getGalleryImages = (state: RootState) => path(state).images;
export const getCurrentGalleryImage = (state: RootState) => {
  const gallery = path(state).images;
  const currentIdx = path(state).currentImageIdx;

  if (gallery.length === 0) {
    return { current: null, next: null, previous: null };
  }

  const currentImage = gallery[currentIdx];

  // Circular logic for next and previous indices
  const nextIdx = (currentIdx + 1) % gallery.length;
  const prevIdx = (currentIdx - 1 + gallery.length) % gallery.length;

  const nextImage = gallery[nextIdx];
  const previousImage = gallery[prevIdx];

  return {
    currentImage: currentImage || null,
    nextImage: nextImage || null,
    previousImage: previousImage || null,
  };
};
export const getGalleryCurrentImageIndex = (state: RootState) =>
  path(state).currentImageIdx;
export const getGalleryIsFirstImage = (state: RootState) =>
  path(state).currentImageIdx === 0;
export const getGalleryIsLastImage = (state: RootState) =>
  path(state).currentImageIdx === path(state).images.length - 1;
export const isGalleryLoading = (state: RootState) => path(state).isLoading;
export const isGalleryPreloading = (state: RootState) =>
  path(state).isPreloading;
